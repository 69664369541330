import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import { Loading, Message, MessageBox } from 'element-ui';
import moment from 'moment';
import VueResource from 'vue-resource';
import Browser from './components/Browser'

Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.axios = axios
axios.defaults.baseURL = '/api'
Vue.use(VueResource)


Vue.component(Browser)
// 获取用户使用的浏览器
let info = new Browser()
if (info.browser == 'IE') {
  MessageBox.alert("系统检测到您正在使用IE浏览器，因系统使用的部分第三方控件不支持IE浏览器，为保障您正常操作，建议使用非IE浏览器！", {
    confirmButtonText: '确定',
    showClose: false,
    type: 'warning'
  }).then(() => {
    // 点击确定刷新页面,使其无法进行后续操作
    router.go(0)
  })
}


//定义loading变量
let loading

// 创建loading方法
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.1)'
    // target: "html",
  });
}

// 关闭loading
function endLoading() {
  loading.close()
}

// showFullScreenLoading() tryHideFullScreenLoading() 要干的事就是将同一时刻的请求合并。
// 声明一个变量 needLoadingRequestCount,每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
// 调用tryHideFullScreenLoading()方法,needLoadingRequestCount - 1。needLoadingRequestCount为 0 时,结束 loading。

let needLoadingRequestCount = 0
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}


// request请求拦截器
axios.interceptors.request.use(
  config => {
    // 开始发送请求 打开loading
    showFullScreenLoading()

    // var token = sessionStorage.getItem('token');
    // if (token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
    //   config.headers["haneton-guarantee"] = token;
    // }
    return config
  },
  error => {
    Promise.reject(error)
  }
);

// response响应拦截器
axios.interceptors.response.use(
  response => {
    // 接收到数据 关闭loading
    tryHideFullScreenLoading()
    return response
  },
  error => {
    if (error.response.status >= 500) {
      Message.error('服务器错误');
    } // else if (error.response.status == -99) {
    //   Message.error('登录已过期,请重新登录');
    // }
    // 接收数据失败 关闭loading
    tryHideFullScreenLoading()
    return Promise.reject(error)
  }
)

// 路由守卫
router.beforeEach((to, from, next) => {
  //如果进入到的路由是登录页,则正常展示
  if (to.path == '/login') {
    next();
  } else if (!(sessionStorage.getItem('type'))) {
    // Message.warning('未登录,请先登录');
    next('/login'); //转入login登录页面，登录成功后会将firmName存入sessionStorage
  } else {
    next(); // 以上全部满足 则什么都不做
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
