import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: resolve => require(['@/views/login'], resolve),
  },
  {
    path: "/homePage",
    name: "homePage",
    component: resolve => require(['@/views/homePage'], resolve),
    children: [
      {
        path: '/main',
        name: "main",
        component: resolve => require(['@/views/main'], resolve)
      },
      {
        path: '/kaipiao',
        name: "kaipiao",
        component: resolve => require(['@/views/kaipiao'], resolve)
      },
      {
        path: '/message',
        name: "message",
        component: resolve => require(['@/views/message'], resolve)
      },
      {
        path: '/online',
        name: "online",
        component: resolve => require(['@/views/online'], resolve)
      },
      {
        path: '/invoice',
        name: "invoice",
        component: resolve => require(['@/views/invoice'], resolve)
      },
      {
        path: '/history',
        name: "history",
        component: resolve => require(['@/views/history'], resolve)
      },
      {
        path: '/agreement',
        name: "agreement",
        component: resolve => require(['@/views/agreement'], resolve)
      },
      {
        path: '/salary',
        name: "salary",
        component: resolve => require(['@/views/salary'], resolve)
      },
      {
        path: '/account',
        name: "account",
        component: resolve => require(['@/views/account'], resolve)
      },
      {
        path: '/enterprise',
        name: "enterprise",
        component: resolve => require(['@/views/enterprise'], resolve)
      },
    ]
  },
  {
    path: "*",
    name: "error404",
    component: resolve => require(['@/views/error404'], resolve),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
